/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mt20 {
  margin-top: 20px;
}
.mr30 {
  margin-right: 30px;
}
.ptb5 {
  padding: 5px 0px;
}
.ptb10 {
  padding: 10px 0px;
}
.d-flex {
  display: flex;
}
.delicon-flex {
  display: flex;
  justify-content: space-between;
}
/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/* You can add global styles to this file, and also import other style files */

/*Font family*/
@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato-BoldItalic.woff2") format("woff2"),
    url("assets/fonts/Lato-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato-Black.woff2") format("woff2"),
    url("assets/fonts/Lato-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato-Bold.woff2") format("woff2"),
    url("assets/fonts/Lato-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato-Italic.woff2") format("woff2"),
    url("assets/fonts/Lato-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato-BlackItalic.woff2") format("woff2"),
    url("assets/fonts/Lato-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato-Regular.woff2") format("woff2"),
    url("assets/fonts/Lato-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato-LightItalic.woff2") format("woff2"),
    url("assets/fonts/Lato-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato-Light.woff2") format("woff2"),
    url("assets/fonts/Lato-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato Hairline";
  src: url("assets/fonts/Lato-HairlineItalic.woff2") format("woff2"),
    url("assets/fonts/Lato-HairlineItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

.font-lato-Regular {
  font-family: "Lato_Regular";
}

.font-semi-bold {
  font-family: "Lato_Semi_Bold";
}

/* LATO FONT FAMILY END */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif !important;
  /*FireFox*/
  scrollbar-width: none;
  /*IE10+*/
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    background: transparent;
    /* Chrome/Safari/Webkit */
    width: 0px;
  }
}

.mat-checkbox,
.mat-button-toggle,
.mat-form-field,
.mat-icon-button {
  font-family: "Lato", sans-serif !important;
}

// Material custom css

.custom-mat-form-field.mat-form-field-appearance-outline {
  width: 100%;

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    top: -6px;
  }

  .mat-form-field-infix {
    top: -10px;
    padding: 12px 0px 4px 0px;
    font-size: 14px;
  }

  .mat-form-field-outline {
    height: 40px;
  }

  .mat-form-field-outline-start {
    // border-radius: 50px 0 0 50px !important;
    width: 25px !important;
  }

  .mat-form-field-outline-end {
    // border-radius: 0 50px 50px 0 !important;
    width: 25px !important;
  }

  .mat-form-field-flex {
    height: 40px;
  }

  .mat-select-arrow-wrapper {
    transform: none;
  }

  .mat-select-value {
    color: #9c9c9c;
  }

  .mat-form-field-outline-thick {
    color: #dfdfdf;
  }

  /* datepicker icon colcor */
  .mat-datepicker-toggle-default-icon {
    color: #828282 !important;
  }
}
.mat-button-focus-overlay {
  background: transparent !important;
}

.mat-form-field:not(.mat-form-field-appearance-legacy)
  .mat-form-field-suffix
  .mat-icon-button {
  display: inline-flex;
  // justify-content: ;
  align-items: center;
  font-size: inherit;
  width: 2.5em;
  height: 2.5em;
  position: absolute;
  left: -25px;
  bottom: -3px;
}

.changePlanPopup {
  margin-top: 0% !important;
  margin-left: 10% !important;

  .mat-dialog-container {
    padding: 0px 15px;
    border-radius: 15px 15px 15px 15px;
    max-height: 553px;
  }
}

.custom-mat-form-field.custom-datpicker .mat-form-field-suffix {
  top: 3px !important;
  font-size: 13px;
}

/* To change mat-checkbox background color after checkbox is checked the background color should be blue-clr */
.custom-mat-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.custom-mat-checkbox.mat-checkbox-indeterminate.mat-accent
  .mat-checkbox-background {
  background-color: #0066af;
  border-radius: 5px;
}

.custom-mat-checkbox .mat-checkbox-layout {
  white-space: normal !important;
}

.custom-mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  line-height: 2;
}

.custom-mat-checkbox .mat-checkbox-inner-container {
  width: 24px;
  height: 24px;
}

.custom-mat-checkbox.mat-accent .mat-checkbox-frame {
  border: 1px solid #9c9c9c;
  border-radius: 5px;
}

.custom-mat-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-frame {
  border: 1px solid #0066af;
  border-radius: 5px;
}

/* custom radion button css */
/* radio button css */
.custom-radio-btn .custom-radio-button-margin {
  width: 48%;
  margin-bottom: 15px;
}

.custom-radio-btn .mat-accent {
  padding: 4px 0px;
}

.custom-radio-btn .mat-radio-checked .mat-radio-outer-circle {
  background: #1478f6;
  border: #1478f6 !important;
}

.custom-radio-btn .mat-radio-inner-circle {
  display: none;
}

// Material custom css end

/* common css for all components */

.cursor-pointer {
  cursor: pointer;
}

.form-label {
  color: #9c9c9c;
  font-size: 12px;
  margin-bottom: 0;
}

.page-back {
  color: #000;
  cursor: pointer;

  &:hover {
    color: #000;
  }

  .mat-icon {
    line-height: 1.5;
  }
}

.asterisk-mandtry:after {
  content: "*";
  position: absolute;
  color: #f27f21;
}

.age-field {
  color: #fcc5b6;
  position: relative;
  top: 0px;
  left: -20px;
  font-size: 14px;
}
.mat-form-field:not(.mat-form-field-appearance-legacy)
  .mat-form-field-prefix
  .mat-icon-button,
.mat-form-field:not(.mat-form-field-appearance-legacy)
  .mat-form-field-suffix
  .mat-icon-button {
  width: 32px !important;
}

.submit-btn {
  background: linear-gradient(180deg, #ffb171 0%, #ff943d 100%);
  color: #fff;
  border-radius: 18px !important;
  width: 100%;
}

button:focus {
  outline: 0 !important;
  box-shadow: unset !important;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-12 {
  font-size: 12px;
}

.custom-radio-btn .mat-radio-checked .mat-radio-outer-circle {
  background: linear-gradient(180deg, #ffb171 0%, #ff943d 100%) #ff943d !important;
  border: linear-gradient(180deg, #ffb171 0%, #ff943d 100%) !important;
}

.sharePopupClass {
  margin-left: 10% !important;
  margin-top: 5% !important;
}

.policyAlertClasss {
  margin-left: 10% !important;
}

/* End common css for all components */
.otp-input {
  border-radius: 25px !important;
  font-size: 20px !important;
  color: #535353;
}

.visibility {
  visibility: hidden;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #f7954f !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f7954f !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #f7954f !important;
}

.hideEmptyPlan {
  visibility: hidden;
}

.mat-dialog-container {
  display: block;
  padding: 24px;
  border-radius: 18px !important;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 52%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

.check-pincode-modal {
  height: auto !important;
  margin-top: 10% !important;
  margin-left: 30%;

  .mat-dialog-container {
    border-radius: 20px !important;
    width: 60% !important;
  }

  .pincode-popup-width {
    padding: 0px 40px;
  }
}

#healthv2-quote-page {
  .mat-form-field-outline-start {
    // border-radius: 50px 0 0 50px;
    width: 25px;
  }

  .mat-form-field-outline-end {
    // border-radius: 0 50px 50px 0;
    width: 25px;
  }
}
#healthv2-review-page {
  .custom-mat-form-field.mat-form-field-appearance-outline
    .mat-form-field-outline {
    background-color: #f9f9f9;
  }
}
.policyform {
  .mat-radio-container {
    position: relative;
  }

  /* Styles for the checked state */
  .mat-radio-checked .mat-radio-inner-circle::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 44px !important;
    height: 44px !important;
    background-image: url("/assets/images/health-v2/icons/radio-check.svg"); /* Path to your image */
    background-size: contain;
    background-repeat: no-repeat;
  }
  .mat-radio-button.mat-primary .mat-radio-inner-circle {
    background-color: #ffffff;
  }
  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: #ffffff;
  }
}

.sharelinkpopup {
  margin-top: 10% !important;
  margin-left: 10% !important;
  margin-right: 25% !important;

  .mat-dialog-container {
    padding: 15px;
    border-radius: 15px 15px 15px 15px;
    margin: 0px 10px;
    .close-icon {
      display: block;
      position: relative;
      text-align: right;
      width: 32px;
      left: 92%;
      bottom: 5px;
      cursor: pointer;
    }
  }
}

.mat-menu-panel {
  width: 100% !important;
}
button.mat-menu-item {
  width: 100%;
  margin: 0 auto !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e9e7e7da;
  font-size: 13px !important;
  line-height: 16.8px;
  font-weight: 500 !important;
  color: #828282;
}
.mat-menu-content:not(:empty) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pricebreakuppopup {
  height: auto !important;
  margin-top: 10% !important;
  // margin-left: 30%;

  .mat-dialog-container {
    border-radius: 20px !important;
    // width: 52% !important;
    padding: 0px 0px !important;
  }

  .pincode-popup-width {
    padding: 0px 40px;
  }
}

#network-hospital-card {
  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: #ff943d;
  }
  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-inner-circle {
    background-color: #ff943d;
  }
  .mat-radio-label-content {
    padding-right: 20px;
    font-size: 14px;
    line-height: 17.07px;
    font-weight: 400;
    color: #000000;
  }

  .mat-radio-button.mat-radio-checked .mat-radio-label-content {
    font-weight: 500;
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    top: -5px;
  }
}
.otpPopupClass {
  width: 32% !important;
  min-width: 32% !important;
}
@media only screen and (max-width: 768px) {
  .sharePopupClass {
    margin-left: 2% !important;
    margin-top: 70% !important;
  }
  .otpPopupClass {
    width: 50% !important;
    min-width: 95% !important;
    margin-top: 10% !important;
  }
  .policyAlertClasss {
    margin-left: 2% !important;
    margin-top: 30% !important;
  }

  .changePlanPopup {
    margin-top: 30% !important;
    margin-left: 0% !important;

    .mat-dialog-container {
      padding: 0px;
      border-radius: 15px 15px 15px 15px;
      margin: 0px 10px;
    }
  }

  .MobVerifyPopup {
    margin-left: 2% !important;
    margin-top: 58% !important;
  }

  .mat-form-field-infix {
    top: -4px !important;
    padding: 4px 0px 4px 0px !important;
  }

  .check-pincode-modal {
    height: auto !important;

    width: 90% !important;
    margin: 10% auto !important;

    .mat-dialog-container {
      border-radius: 20px !important;
      width: 100% !important;
    }

    .pincode-popup-width {
      padding: 0px 2px;
    }
  }

  .sharelinkpopup {
    margin-top: 10% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;

    .mat-dialog-container {
      padding: 10px;
      border-radius: 15px 15px 15px 15px;
      margin: 0px 0px;
      .close-icon {
        display: block;
        position: relative;
        text-align: right;
        width: 32px;
        left: 84%;
        bottom: 5px;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }

  button.mat-menu-item {
    width: 100%;
    margin: 0 auto !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: solid 1px #e9e7e7da;
    font-size: 13px !important;
    line-height: 16.8px;
    font-weight: 500 !important;
    color: #828282;
  }
  .mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .mat-menu-panel {
    width: 100% !important;
  }

  #healthv2-quotelist-page {
    .mat-form-field:not(.mat-form-field-appearance-legacy)
      .mat-form-field-suffix
      .mat-icon-button {
      bottom: -10px !important;
    }
    .mat-form-field:not(.mat-form-field-appearance-legacy)
      .mat-form-field-suffix
      .mat-icon-button {
      bottom: -8px;
    }
  }
  .policyform {
    .mat-radio-button.mat-primary .mat-radio-inner-circle {
      background-color: transparent;
    }
    .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
      border-color: #f27f21;
    }
  }

  #bmi-popup {
    .mat-dialog-container {
      padding: 10px;
      border-radius: 15px 15px 15px 15px;
      margin: 0px 0px;
      .close-icon {
        display: block;
        position: relative;
        text-align: right;
        width: 32px;
        left: 84%;
        bottom: 5px;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }

  #network-hospital-card {
    .mat-form-field-prefix,
    .mat-form-field-suffix {
      top: 0px;
    }
  }
}
